<template>
	<div class="jobhunting">
		<div class="login">
			<div class="contain">
				<img src="../assets/img/loginc.png" />
				<div class="el-card box-card login_body is-always-shadow">
					<div class="right">
						<!-- 切换 
						<div class="mode mode1" @click="smLOGIN" v-if="sm">
							<div class="modeText">
								账号密码登录
							</div>
							<img src="../assets/img/company/dn.png" alt="Alternate Text" />
						</div>
						<div class="mode mode1" @click="yzmLOGIN" v-if="yzm">
							<div class="modeText">
								微信扫码登录
							</div>
							<img src="../assets/img/company/erwm.png" alt="Alternate Text" />
						</div>-->
						<!-- 扫描 -->
						<div v-if="sm">
							<div class="scanning">
								<div class="title">扫码登录</div>
								<div class="qrcode" ref="qrCodeUrl"></div>
								<div>使用<b>【微信扫一扫】</b><br>功能扫码登录</div>
							</div>
						</div>
						<!-- 电脑 -->
						<div v-show="yzm">
							<!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
								<el-tab-pane label="登录/注册" name="first"></el-tab-pane>
								<el-tab-pane label="密码登录" name="second"></el-tab-pane>
							</el-tabs> -->
							<!-- 密码 -->
							<div v-if="activeName == 'first'">
								<div class="textIn">
									<div class="el-row card_input">
										<span class="input_label">手机号</span>
										<div class="el-input el-input--prefix">
											<input type="text" class="el-input__inner" v-model="subfrom.mobile"
												placeholder="手机号码" />
										</div>
									</div>
									<div class="el-row card_input">
										<span class="input_label">验证码</span>
										<div class="el-input el-input--prefix">
											<input type="text" class="el-input__inner" v-model="subfrom.code"
												placeholder="请输入" />
											<span class="el-input__suffix">
												<span class="el-input__suffix-inner">
													<span class="primary_span sent_code">获取验证码</span>
												</span>
											</span>
										</div>
									</div>
								</div>
							</div>
							<!-- 短信 -->
							<div v-if="activeName == 'second'">
								<div class="textIn">
									<div class="el-row card_input">
										<span class="input_label">用户名</span>
										<div class="el-input el-input--prefix">
											<input type="text" class="el-input__inner" v-model="subfrom.mobile"
												placeholder="请输入用户名" />
										</div>
									</div>
									<div class="el-row card_input">
										<span class="input_label">密码</span>
										<div class="el-input el-input--prefix">
											<input type="password" class="el-input__inner" v-model="subfrom.password"
												placeholder="请输入密码" />
										</div>
									</div>
									<div class="el-row card_input">
										<span class="input_label">验证码</span>
										<div class="el-input el-input--prefix">
											<input type="text" class="el-input__inner" v-model="subfrom.code"
												placeholder="请输入验证码" />
											<span id="canvasCode" class="code"></span>
										</div>
									</div>
									<div class="line">
									</div>
								</div>
							</div>
							<div class="textIn">
								<div class="clear"></div>
								<div class="free">
									<el-checkbox v-model="cbxbox">记住我</el-checkbox>
								</div>
								<div class="btn" @click="logininfo()">登录</div>
								<div class="free">
									<a><!-- 忘记密码 --></a>
									<a @click="goto('/registerc')">注册账户</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		UserLogin,
		CompanyLogin,
		Getscanuserinfo
	} from "@/api/user";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		getGVerify
	} from "@/api/home";
	import QRCode from "qrcodejs2";
	import global from "@/assets/js/globalconfig";
	import { mapMutations,mapState} from 'vuex' // 引入useStore 方法
	export default {
		data() {
			return {
				activeName: "second",
				api: global.baseurl,
				type: 2,
				sm: false,
				yzm: true,
				timer: null,
				state: "",
				subfrom: {
					mobile: "",
					password: "",
				},
				isback: false,
				qrcode: new Array(),
				GVerifyCode: null,
				cbxbox: false
			};
		},
		created() {
			
			var query = this.$route.query;
			var _this = this;
			if (!isnull(query.type)) {
				_this.type = parseInt(query.type);
				_this.isback = query.isback;
			}
		},
		mounted() {
			//this.creatQrCode();
			this.subfrom.mobile = localStorage.getItem("mobile2");
			this.subfrom.password = localStorage.getItem("password2");
			this.getGVerify();
		},
		beforeDestroy() {
			clearInterval(this.timer);
		},
		methods: {
			getGVerify() {
				this.GVerifyCode = getGVerify({
					id: "canvasCode",
					width: "100",
					height: "48",
					fontSize: "26",
					numSize: 4,
					type: "blend",
				});
			},
			tabType() {
				this.activeName = this.type === 1 ? "first" : "second";
				this.$refs.qrCodeUrl.innerHTML = "";
				this.creatQrCode();
			},
			send() {

			},
			creatQrCode() {
				var _this = this;
				if (_this.timer != null) {
					clearInterval(_this.timer);
				}
				_this.state = _this.getTimestamp();
				// _this.
				try {
					_this.qrcode = new QRCode(_this.$refs.qrCodeUrl, {
						text: _this.api +
							`/apipc/wxlogin?surl=lxapi.digintel.net&spath=/apipc/wxlogin&state=${_this.state}${_this.type}`, // 需要转换为二维码的内容
						width: 150,
						height: 150,
						colorDark: "#000000",
						colorLight: "#ffffff",
						correctLevel: QRCode.CorrectLevel.H,
					});
					_this.getuserinfo();
				} catch (error) {
					_this.creatQrCode();
				}
			},
			getTimestamp() {
				//把时间日期转成时间戳
				return new Date().getTime();
			},

			getuserinfo() {
				var _this = this;
				this.timer = setInterval(() => {
					_this.Getscanuserinfos();
				}, 3000);
			},
			Getscanuserinfos() {
				var _this = this;
				Getscanuserinfo({
					code: _this.state,
					types: _this.type
				}).then((res) => {
					if (res.success) {
						if (res.data.type === 1) {
							_this.$message({
								message: "扫码成功",
								type: "success",
							});
							//新用户
							clearInterval(_this.timer);
							localStorage.setItem("wxtoken", res.data.rcOpenId);
							_this.resolvewin("/register", {
								type: _this.type
							}, 1);
						} else if (res.data === -1) {
							//暂无扫码
						} else if (res.data.type === 0) {
							_this.$message({
								message: "扫码成功",
								type: "success",
							});
							clearInterval(_this.timer);
							_this.subfrom.mobile = res.data.user[0].coM_ACCOUNT;
							_this.subfrom.password = res.data.user[0].coM_PWD;
							_this.subfrom.type = _this.type;
							_this.logininfo();
							//老用户
						}
					}
				});
			},
			handleClick() {
				this.isDisabled = false;
				this.subfrom.mobile = "";
				this.subfrom.name = "";
				this.subfrom.password = "";
				this.subfrom.repassword = "";
			},
			smLOGIN: function() {
				this.sm = false;
				this.yzm = true;

				clearInterval(this.timer);
			},
			yzmLOGIN: function() {
				var _this = this;
				setTimeout(() => {
					_this.creatQrCode();
				}, 500);
				this.sm = true;
				this.yzm = false;
			},
			goto(url) {
				this.$router.push(url);
			},
			topage(e) {
				this.$router.push(e);
			},
			logininfo() {
				var _this = this;
				if (isnull(_this.subfrom.mobile)) {
					_this.$message.error("请输入用户名");
					return false;
				}
				if (isnull(_this.subfrom.password)) {
					_this.$message.error("请输入密码");
					return false;
				}
				var flag = this.GVerifyCode.validate(_this.subfrom.code);
				if (!flag) {
					this.$message({
						message: "验证码错误",
						type: "warning",
					});
					this.GVerifyCode.refresh();
					return false;
				}
				var rdatas = new Array();
				CompanyLogin(_this.subfrom).then((res) => {
					if (res.success) {
						this.$message({
							message: "登录成功",
							type: "success",
						});
						var rdata = res.data;
						rdata.user.userType = _this.type;
						localStorage.setItem("token", rdata.token);
						localStorage.setItem("userinfo", JSON.stringify(rdata.user));
						if (_this.cbxbox) {
							localStorage.setItem("mobile2", _this.subfrom.mobile);
							localStorage.setItem("password2", _this.subfrom.password);
						}
						if (_this.isback) {
							setTimeout(_this.$router.go(-1), 500);
						} else if (_this.type == 2) {
							setTimeout(_this.resolvewin("/companycenter?type=0", {}, 1), 500);
						} else {
							setTimeout(_this.resolvewin("/", {}, 1), 500);
						}
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	// tab
	/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
		border: none;
		border-radius: 0;
	}

	/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
		border: none;
	}

	/deep/.el-tabs--card>.el-tabs__header {
		border: none;
		margin: 0;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
		width: 100%;
		display: flex;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item {
		height: 45px;
		text-align: center;
		box-sizing: border-box;
		border-bottom: 1px solid #fff;
		font-size: 18px;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		color: #276bf2;
		border-bottom: 1px solid #276bf2;
	}

	/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:hover {
		color: #276bf2;
	}

	// 内容
	.login {
		width: 100%;
		height: calc(100vh - 125px);
		background: #a1c6f0;
		display: flex;
		align-items: center;
		justify-content: center;

		.contain {
			width: 100%;
			position: relative;
			height: 100%;
		}

		.contain>img {
			position: absolute;
			top: 10%;
			left: 10%;
			max-width: 45%;
		}

		.login_body {
			z-index: 99;
			width: 408px;
			position: absolute;
			top: 10%;
			right: 10%;
			border-radius: 12px;
			min-height: 466px;
			box-sizing: border-box;

			.right {
				padding: 20px;
				padding-top: 60px;
				box-sizing: border-box;
				position: relative;
				border-radius: 12px;

				.scanning {
					padding-top: 50px;

					.qrcode {
						margin-bottom: 20px;
						display: flex;
						justify-content: center;
						align-content: space-between;
					}

					.radio-group {
						margin-bottom: 10px;
					}

					font-size: 14px;
					color: #999;
					text-align: center;

					img {
						width: 215px;
						margin-top: 40px;
					}

					b {
						color: #276bf2;
					}

					.title {
						font-size: 20px;
						color: #333;
						margin-bottom: 20px;
					}
				}

				.mode {
					position: absolute;
					top: 6px;
					right: 6px;
					display: flex;
					align-items: center;
					z-index: 996;

					.modeText {
						width: 100px;
						height: 29px;
						color: #999999;
						font-size: 12px;
						line-height: 29px;
						text-align: center;
						margin-left: 15px;
						border-radius: 4px;
						position: relative;
						box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
					}

					img {
						width: 56px;
					}
				}

				.mode:hover {
					cursor: pointer;
				}

				.textIn {
					width: 100%;

					.card_input {
						margin-top: 25px;
						position: relative;
					}

					.el-input__inner {
						height: 48px;
					}

					.input_label {
						position: absolute;
						background-color: white;
						z-index: 20;
						left: 10px;
						top: -10px;
						font-size: 12px;
						display: block;
						padding: 0px 6px;
						color: #666;
						border: 1px solid white;
						border-radius: 10px;
					}

					.input_labal_color {
						color: #2a96ff
					}

					.el-input__suffix {
						position: absolute;
						height: 100%;
						right: 5px;
						top: 0;
						text-align: center;
						color: #b4b4b4;
						transition: all .3s;
						pointer-events: none;
					}

					.code {
						height: 48px;
						position: absolute;
						right: 0;
					}

					.primary_span {
						font-size: 14px;
						color: #666;
						display: inline-block;
						cursor: pointer;
						color: #2a96ff;
					}

					.btn {
						width: 100%;
						height: 40px;
						line-height: 40px;
						text-align: center;
						font-size: 14px;
						color: white;
						background: #0096FF;
						margin-top: 15px;
						border-radius: 4px;
						cursor: pointer;
					}

					.free {
						color: #999;
						font-size: 14px;
						margin-top: 15px;
						display: flex;
						justify-content: space-between;
						align-content: center;

						a {
							color: #467DF2
						}

						label {
							cursor: pointer;
						}

						label:hover {
							color: #467DF2
						}
					}

					.free:hover {
						cursor: pointer;
					}
				}

				.tips {
					width: 100%;
					font-size: 12px;
					color: #999;
					text-align: center;
					line-height: 2;
					margin-top: 20px;

					b {
						color: #276bf2;
					}
				}
			}
		}
	}
</style>
